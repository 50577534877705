import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Image from '../components/hacks/image'
import SEO from '../components/SEO/seo'
import WorkBlock from './../components/Blocks//workBlock'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'

import Slider from 'react-slick'

export default function Template({ data, pageContext }) {
  // this prop will be injected by the GraphQL query below.
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const firstImage = frontmatter.images[0]
  const [open, setOpen] = useState(false)

  var slides = frontmatter.images.map((image, index) => {
    if (image.image) {
      return (
        <div className={`slide`} key={`${index}`}>
          <Image src={image.image} alt={image.caption} />
          <p className={`caption text-center mb-0`}>{image.caption}</p>
        </div>
      )
    }
    else{
      return null
    }
  })
  function SampleNextArrow({ className, onClick }) {
    return (
      <button className={className} onClick={onClick}>
        <span>&rarr;</span>
      </button>
    )
  }

  function SamplePrevArrow({ className, onClick }) {
    return (
      <button className={className} onClick={onClick}>
        <span>&larr;</span>
      </button>
    )
  }

  const slider = {
    dots: false,
    infinite: true,
    initialSlide: open,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => console.log(current, next),
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <Layout dark={frontmatter.dark} hidefooter={open}>
      <SEO
        pageTitle={frontmatter.title}
        pageDescription={frontmatter.metaDescription ? frontmatter.metaDescription : html}
        pageImage={frontmatter.thumbnail.relativePath}
        url={frontmatter.path}
      />
      {open === false ? (
        <ParallaxProvider>
          <article className="pt-5 container-fluid">
            <div className="row pt-lg-5">
              <div className="col-lg-12">
                <div className="row">
                  <div
                    className={`${
                      !firstImage.image && 'offset-lg-3'
                    } col-md-12 my-5 col-lg-6 order-lg-1`}
                  >
                    <h1 className="post-title">{frontmatter.title}</h1>
                    <p
                      className="lead"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </div>
                  {firstImage.image && (
                    <div
                      className="col-lg-6 order-lg-0 cursor-pointer"
                      onClick={() => setOpen(0)}
                      role="button"
                    >
                      <Image
                        src={firstImage.image}
                        alt={firstImage.caption}
                        className={!frontmatter.captions ? 'mb-lg-5' : 'mb-3'}
                      />
                      <p
                        className={`caption mb-0 small mb-lg-5 ${
                          !frontmatter.captions && 'collapse'
                        }`}
                      >
                        {firstImage.caption}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-10 offset-lg-1">
                <div className="row grid-10">
                  {frontmatter.images.map((image, index) => (
                    <React.Fragment key={image.id + '_' + index}>
                      {index !== 0 && (
                        <React.Fragment>
                          <div
                            className={`cursor-pointer mt-5 col-12 col-md-6 col-lg-${image.size}`}
                            key={image.id}
                            onClick={() => setOpen(index)}
                            role="button"
                          >
                            <Image
                              src={image.image}
                              alt={image.caption}
                              className={
                                !frontmatter.captions ? 'mb-lg-5' : 'mb-3'
                              }
                            />
                            <p
                              className={`caption mb-0 small mb-lg-5 ${
                                !frontmatter.captions && 'collapse'
                              }`}
                            >
                              {image.caption}
                            </p>
                          </div>
                          {image.clear && (
                            <div className="clearfix d-none d-lg-block" />
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div className="col-lg-8 offset-lg-2 my-5 py-lg-5">
                <div className="row justify-content-center">
                  <div className="col-lg-10 ">
                    <Parallax x={[30, 1]}>
                      <h2 className="massive text-uppercase">More?</h2>
                    </Parallax>
                  </div>
                  <WorkBlock
                    work={
                      pageContext.nextPost
                        ? pageContext.nextPost.node.frontmatter.path
                        : '/work/swim/'
                    }
                    image_leftright={'RIGHT'}
                    notsmaller={true}
                  />
                </div>
              </div>
            </div>
          </article>
        </ParallaxProvider>
      ) : (
        <React.Fragment>
          <div
            id="closeslider"
            onClick={() => setOpen(false)}
            role="complementary"
          />
          <Slider {...slider}>{slides}</Slider>
        </React.Fragment>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        dark
        title
        captions
        metaDescription
        thumbnail {
          relativePath
        }
        images {
          size
          clear
          caption
          image {
            id
            relativePath
            childImageSharp {
              fluid(maxWidth: 1400, quality: 79) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            # desktop: childImageSharp {
            #   fluid(maxWidth: 1400, quality: 79) {
            #     ...GatsbyImageSharpFluid_withWebp
            #   }
            # }
            # mobileimage: childImageSharp {
            #   fluid(maxWidth: 7000, quality: 79) {
            #     ...GatsbyImageSharpFluid_withWebp
            #   }
            # }
          }
        }
      }
    }
  }
`
